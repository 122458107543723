import React from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Navigation from "../components/navigation"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Navigation/>

      <section className="content__body--travel">
        {data.allMarkdownRemark.edges.map(function (edge) {
          var node = edge.node
          if ( node.frontmatter.policy === "show" ) {
             var style = css`
             &:after {
               @media only screen and (min-width: 768px) {
                 background-image: url(/huellas${ node.fields.slug }l.jpg);
               }

               background-image: url(/huellas${ node.fields.slug }s.jpg);
             }
             `
             return <Link key={ node.id } css={ style } to={ node.fields.slug }>
                      <span>{ node.frontmatter.title }</span>
                    </Link>
          }

          return <></>
        })}
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query
  {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node{
          id
          frontmatter {
            title,
            policy
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
